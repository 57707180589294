import React from "react"
import Layout from "../component/Layout"
import horse from "../images/icons/horse.png"
import archer from "../images/icons/archery.png"
import diver from "../images/icons/diver.png"
import canoe from "../images/icons/canoe.png"
import train from "../images/icons/train.png"
import shooting from "../images/icons/shooting.png"
import ape from "../images/icons/ape.png"
import tree from "../images/icons/tree.png"
import wood from "../images/icons/wood.png"
import cave from "../images/icons/cave.png"
import castle from "../images/icons/castle.png"
import SEO from "../component/SEO"
import "../styles/todo.scss"
import Quotes from "../component/Quotes"

const toDo = () => {
  return (
    <Layout>
      <SEO title="To Do" />
      <section className="hero-content todo">
        <div className="grid"></div>
        <div className="hero-title">
          <div className="inner-wrap">
            <h1>Things To Do</h1>
            <h2>South Wales - Just outside Chepstow</h2>
          </div>
        </div>
      </section>
      <Quotes quote="The Local Area Is Full Of Great Things To Do" />
      <section className="todo-content">
        <div className="cell">
          <div className="text">
            <h4>Things to do</h4>
            <p>
              The local attractions offer something for all ages, from city life
              in Bristol, 30 minutes away to forest trails and adventure
              playgrounds for children.
            </p>
            <p>
              Close by are magical days out in the Forest of Dean, 30 mins drive
              including Puzzlewood, The Sculpture Trail, Offas Dyke Path, The
              Speech House, Taurus Crafts and Welsh Castles.
            </p>
            <p>
              It is ideally located to visit the Wye valley, Forest of Dean,
              Chepstow Racecourse, Tintern Abbey, Brecon Beacons, market towns
              of Chepstow and Monmouth.
            </p>
            <p>
              Bristol City Centre is 22.8 miles by car, Bath 35.6 miles and
              Cardiff 36.9 miles. Further information can be found in the house.
            </p>
          </div>
        </div>
        <div className="cell todo-pic">
          {/* <!--CELL TWO HAS A PICTURE--> */}
        </div>
      </section>

      <section className="todo-content eat-content">
        <div className="cell eat-pic">
          {/* <!--CELL TWO HAS A PICTURE--> */}
        </div>
        <div className="cell">
          <div className="eat-place">
            <h4>Places to eat</h4>
            <div className="eat">
              <div className="pub">
                <h5>The Piercefield</h5>
                <a
                  href="https://www.sabrain.com/pubs-and-hotels/south-wales/monmouthshire/the-piercefield/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  piercefield
                </a>
                <p>5 mins drive</p>
              </div>
              <div className="pub">
                <h5>Raglan Arms</h5>
                <a
                  href="http://www.raglanarms.co.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  raglanarms
                </a>
                <p>15 mins drive</p>
              </div>
            </div>
            <div className="eat">
              <div className="pub">
                <h5>The Anchor Inn</h5>
                <a
                  href="http://theanchortintern.co.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  theanchortintern
                </a>
                <p>15 mins drive</p>
              </div>
              <div className="pub">
                <h5>The Star Llansoy</h5>
                <a
                  href="https://www.thestaronthehill.co.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  thestarllansoy
                </a>
                <p>10 mins drive</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="todo-content">
        <div className="cell">
          <h4>Activities</h4>

          <div className="activities">
            <div className="activity">
              <img src={horse} alt="horse icon" />
              <a
                href="http://www.severnvale-equestrian.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Horse Riding
              </a>
              <p>20 mins drive</p>
            </div>

            <div className="activity">
              <img src={archer} alt="archery icon" />
              <a
                href="http://www.wyevalleyarchery.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Archery
              </a>
              <p>15 mins drive</p>
            </div>

            <div className="activity">
              <img src={diver} alt="diver icon" />
              <a
                href="http://www.ndac.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Diving Centre
              </a>
              <p>15 mins drive</p>
            </div>

            <div className="activity">
              <img src={canoe} alt="canoe icon" />
              <a
                href="http://www.monmouthcanoe.co.uk/river-wye-canoeing"
                target="_blank"
                rel="noopener noreferrer"
              >
                Canoe
              </a>
              <p>25 mins drive</p>
            </div>

            <div className="activity">
              <img src={train} alt="train icon" />
              <a
                href="http://www.deanforestrailway.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Forest Railway
              </a>
              <p>30 mins drive</p>
            </div>

            <div className="activity">
              <img src={shooting} alt="shooting icon" />
              <a
                href="http://www.keeperslodgeclayshoot.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Clay Shoot
              </a>
              <p>12 mins drive</p>
            </div>

            <div className="activity">
              <img id="ape" src={ape} alt="ape icon" />
              <a
                href="https://goape.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go Ape
              </a>
              <p>12 mins drive</p>
            </div>

            <div className="activity">
              <img src={tree} alt="tree icon" />
              <a
                href="http://www.forestadventure.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                forestadventure
              </a>
              <p>12 mins drive</p>
            </div>

            <div className="activity">
              <img src={wood} alt="wood icon" />
              <a
                href="http://www.puzzlewood.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                puzzlewood
              </a>
              <p>12 mins drive</p>
            </div>
            <div className="activity">
              <img src={cave} alt="cave icon" />
              <a
                href="http://www.clearwellcaves.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                clearwellcaves
              </a>
              <p>12 mins drive</p>
            </div>
            <div className="activity">
              <img src={castle} alt="castle icon" />
              <a
                href="http://cadw.gov.wales/daysout/chepstow-castle/"
                target="_blank"
                rel="noopener noreferrer"
              >
                chepstowcastle
              </a>
              <p>12 mins drive</p>
            </div>
          </div>
        </div>

        <div className="cell kayak-pic">
          {/* <!--CELL TWO HAS A PICTURE--> */}
        </div>
      </section>
      <section className="todo-content cocktail-content">
        <div className="cell cocktail-pic">
          {/* <!--CELL TWO HAS A PICTURE--> */}
        </div>
        <div className="cell">
          <div className="text">
            <h4>Cocktails &amp; Games</h4>
            <p>
              ‘Cocktails &amp; Games Cocktail Making Class’ is the perfect
              activity to get your party or event started! Making Special
              Memories With Family &amp; Friends!
            </p>
            <a
              href="https://cocktailsandgames.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Their Website
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default toDo
