import React from "react"
import quotesStyles from "./scss/quotes.module.scss"
// import PropTypes from "prop-types"
import Divider from "./Divider"

const Quotes = props => {
  return (
    <div className={quotesStyles.quoteWrap}>
      <Divider />
      <q>{props.quote}</q>
      <p>{props.name}</p>
    </div>
  )
}

export default Quotes
