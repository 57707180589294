import React from "react"
import divStyle from "./scss/divider.module.scss"
import logo from "../images/logo/dorlands_tree_logo.png"

const Divider = () => {
  return (
    <div className={divStyle.container}>
      <span className={divStyle.dot}></span>
      <span className={divStyle.dot}></span>
      <span className={divStyle.line}></span>
      <span className={divStyle.circle}>
        {/* <span className={divStyle.innerCircle}></span>
         */}
        <img src={logo} alt="dorlands barn wedding venue logo" />
      </span>
      <span className={divStyle.line}></span>
      <span className={divStyle.dot}></span>
      <span className={divStyle.dot}></span>
    </div>
  )
}

export default Divider
